import { useQuery } from '@tanstack/react-query';

import { req, useConfig } from './api';

export const useServiciosFijos = () => useConfig('servicios_fijos');

export const useDetalleFijo = (partNumber, enabled) => useQuery({ queryKey: ['detalleFijo', partNumber], queryFn: () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/products/${partNumber}/datasheet`,
}, null), enabled });

export const usePlanAddons = (partNumber, enabled = true) => useQuery({ queryKey: ['planAddons', partNumber], queryFn: () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/products/${partNumber}/plan-addons`,
}, null), enabled });

export const useFormularioFijo = (formId, enabled = true) => useQuery({ queryKey: ['formularioFijo', formId], queryFn: () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/tl_bo_backend/forms/getFormByID?form_id=${formId}`,
  // url: `https://30f800f3-f800-4a50-8996-4961c439fd69.mock.pstmn.io/tl_bo_backend/files/management/form/lite/${formId}`,
}, null), enabled });

export const useProducts = (slug, enabled = true) => useQuery({ queryKey: ['products', slug], queryFn: () => req({
  method: 'GET',
  url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByCategory/${slug}?lang=${process.env.NEXT_PUBLIC_LOCALE}&currency=${process.env.NEXT_PUBLIC_CURRENCY}`,
}, null), enabled });

export const getCobertura = query => req({
  method: 'POST',
  url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/tl_backend/cobertura`,
  data: query,
}, null);

export const postForm = query => req({
  method: 'POST',
  url: `${process.env.NEXT_PUBLIC_AMXGW_HOST}/tl_bo_backend/residentService/createResidentServiceRequest`,
  // url: 'https://30f800f3-f800-4a50-8996-4961c439fd69.mock.pstmn.io/tl_bo_backend/files/management/form/lite/management',
  data: query,
}, null);
