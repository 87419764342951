import React from "react";
// SVG to JSX: http://svg2jsx.herokuapp.com/

import Logo from '~/helpers/svg/logo.svg';
import LogoIcon from '~/helpers/svg/logoIcon.svg';
import IconCart from '~/helpers/svg/iconCart.svg';
import IconMusica from '~/helpers/svg/iconMusica.svg';
import IconVideo from '~/helpers/svg/iconVideo.svg';
import IconMovil from '~/helpers/svg/iconMovil.svg';
import IconHogar from '~/helpers/svg/iconHogar.svg';
import IconPromociones from '~/helpers/svg/iconPromociones.svg';
import IconEntretenimiento from '~/helpers/svg/iconEntretenimiento.svg';
import IconClub from '~/helpers/svg/iconClub.svg';
import IconRegulatorio from '~/helpers/svg/iconRegulatorio.svg';
import IconSad from '~/helpers/svg/iconSad.svg';
import IconQuestion from '~/helpers/svg/iconQuestion.svg';
import IconUserData from '~/helpers/svg/iconUserData.svg';
import IconCheck from '~/helpers/svg/iconCheck.svg';
import Bandera from '~/helpers/svg/bandera.svg';
import XClose from '~/helpers/svg/xclose.svg';

import IcoCustomer from '~/helpers/svg/icoCustomer.svg';
import IcoPortability from '~/helpers/svg/icoPortability.svg';

import SVGArrowPrev from '~/helpers/svg/arrowPrev.svg';
import SVGArrowNext from '~/helpers/svg/arrowNext.svg';

import IcoIncluye from '~/helpers/svg/icoIncluye.svg';
import IcoResumen from '~/helpers/svg/icoResumen.svg';
import IcoEntretenimiento from '~/helpers/svg/icoEntretenimiento.svg';
import IcoConectividad from '~/helpers/svg/icoConectividad.svg';
import IcoEspecificacion from '~/helpers/svg/icoEspecificacion.svg';
import MyProfile from '~/helpers/svg/myProfile.svg';
import MyAddress from '~/helpers/svg/myAddress.svg';
import MyOrders from '~/helpers/svg/myOrders.svg';
import CardVisa from '~/helpers/svg/cardVisa.svg';
import CardMaster from '~/helpers/svg/cardMaster.svg';
import CardAmex from '~/helpers/svg/cardAmex.svg';
import BrowserEdge from '~/helpers/svg/browserEdge.svg';


// -- Layout
export const logo = <Logo />;
export const logoIcon = <LogoIcon />;
export const iconCart = <IconCart />;
export const iconMusica = <IconMusica />;
export const iconVideo = <IconVideo />;
export const iconMovil = <IconMovil />;
export const iconHogar = <IconHogar />;
export const iconPromociones = <IconPromociones />;
export const iconEntretenimiento = <IconEntretenimiento />;
export const iconClub = <IconClub />;
export const iconRegulatorio = <IconRegulatorio />;
export const iconSad = <IconSad />;
export const iconQuestion = <IconQuestion />;
export const iconUserData = <IconUserData />;
export const iconCheck = <IconCheck />;
export const bandera = <Bandera />;
export const xclose = <XClose />;

// -- Modals

export const icoCustomer = <IcoCustomer />;
export const icoPortability = <IcoPortability />;

// -- Product

export const ArrowPrev = props => {
  const { className, onClick } = props;
  return (
    <a className={className} onClick={onClick}>
      <SVGArrowPrev />
    </a>
  );
};
export const ArrowNext = props => {
  const { className, onClick } = props;
  return (
    <a className={className} onClick={onClick}>
      <SVGArrowNext />
    </a>
  );
};

// - ficha técnica

export const icoIncluye = <IcoIncluye />;
export const icoResumen = <IcoResumen />;
export const icoEntretenimiento = <IcoEntretenimiento />;
export const icoConectividad = <IcoConectividad />;
export const icoEspecificacion = <IcoEspecificacion />;

// -- Cross

export const CrossPrev = props => {
  const { className, onClick } = props;
  return <a className={"fa fa-angle-left " + className} onClick={onClick}></a>;
};
export const CrossNext = props => {
  const { className, onClick } = props;
  return <a className={"fa fa-angle-right " + className} onClick={onClick}></a>;
};

//-- Account

export const myProfile = <MyProfile />;
export const myAddress = <MyAddress />;
export const myOrders = <MyOrders />;

// -- Cards

export const cardVisa = <CardVisa />;
export const cardMaster = <CardMaster />;
export const cardAmex = <CardAmex />;

// browsers

export const browserEdge = <BrowserEdge />;
