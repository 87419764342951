import React from "react";
import "~/sass/modules/_esimIndicator.scss";

const ESImIndicator = ({
  label = "Incluye eSIM",
  image = '/images/esim.png',
  top = null,
  customStyles = {}
}) => {

  const imageUrl = `${process.env.NEXT_PUBLIC_CDN_PATH}${image}`;

  return (
    <div className="eSImIndicator" style={{ top: top ? top : null, ...customStyles }}>
      <img className="icon" width="12" src={imageUrl} alt="" />
      <span className="label">
        {label}
      </span>
    </div>
  )

}


export default ESImIndicator;
