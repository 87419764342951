import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createPortal } from "react-dom";
import { useEffect, useRef, useState } from "react";

const MySwal = withReactContent(Swal);

export const SwalModal = config => MySwal.fire({
  showConfirmButton: false,
  showCloseButton: true,
  focusConfirm: false,
  closeButtonHtml: '<i class="fas fa-times red-text"></i>',
  ...config,
  customClass: {
    container: 'tel-swal',
    ...config.customClass,
  },
});

export const SwalModalComp = ({ children, show, onClose, config }) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (show && !container) {
      SwalModal({
        ...config,
        html: <div ref={(el) => setContainer(el)} />,
      }).then(() => {
        onClose && onClose();
      });
    } else if (!show && container) {
      MySwal.close();
    }
  }, [show, onClose, container]);

  if (container) {
    return createPortal(children, container);
  }
  return null;
};
