import { action, observable, makeObservable } from 'mobx';
import { filter, remove } from 'lodash-es';

import request from '~/bin/httpRequest';
import { addonsList } from '~/store/Addons/Addons';
import { apiCategories, getConfigHome, getItemsBySKU, queryClient, queryFunction } from '~/store/Query/api';
import { filtroProductos } from '#/renderer/utils/home';

const clearProductList = (args) => {
  remove(args.data, (n) => {
    const imagesValidations = filter(n.items, 'images');
    const img = (imagesValidations.length ? imagesValidations : null);

    if (!img || !n.available) {
      return n;
    }

    return false;
  });
  return args;
};

function listAddons() {
  return addonsList().then((res) => {
    if (res.success) {
      return res.data[0].filter(x => x.enabled === true && x.show_home === false);
    }
    return [];
  });
}

export const productList = (value, limit, _ = [], destacados) => {
  let paramLimit = '';
  let paramDestacados = '';
  let loading;
  if (limit) {
    paramLimit = `&limit=${limit}`;
  }
  if (destacados) {
    paramDestacados = `&destacados=${destacados}`;
    loading = false;
  }
  const options = {
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByCategory/${value}?lang=${process.env.NEXT_PUBLIC_LOCALE}&currency=${process.env.NEXT_PUBLIC_CURRENCY}${paramLimit}${paramDestacados}`,
    loading,
  };

  return queryClient.fetchQuery({ queryKey: ['productList', value, limit, destacados], queryFn: () => request.genericHandler(options, null).then(async (res) => {
    let callback = { action: 'catalog', success: false };
    if (!res.error) {
      const addons = await listAddons();
      let lista;
      if (destacados) {
        lista = res.data.data.lista;
        callback.planes = res.data.data.planes;
      } else {
        lista = res.data.data;
      }
      lista = filtroProductos(lista, callback.planes);
      const data = addons.length ? lista.filter(p => !addons.some(x => x.part_number === p.part_number)) : lista;
      //console.log(`${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByCategory%2F${value}?lang=${process.env.NEXT_PUBLIC_LOCALE}&currency=${process.env.NEXT_PUBLIC_CURRENCY}`);
      callback = { ...callback, data, success: true };
    } else {
      callback = { ...callback, error: res.error };
    }
    return callback;
  })});
};

const genericFunction = (options, sessionIdDisable, actionCallback) => request.genericHandler(options, sessionIdDisable).then((res) => {
  let callback = { action: actionCallback, success: false };
  if (!res.error) {
    callback = {
      ...callback,
      data: res.data.data,
      success: true,
    };
  } else {
    callback = {
      ...callback,
      error: res.error,
    };
  }
  return callback;
});

class CatalogStore {
  autoSuggestResult = observable.map();

  catalogResponse = observable.map();

  searchResult = observable.map();

  inventoryStatus = observable.map();

  categoryList = observable.map();

  getEsimProductsList = observable.map();

  constructor() {
    makeObservable(this, {
      autoSuggestResult: observable,
      catalogResponse: observable,
      searchResult: observable,
      inventoryStatus: observable,
      categoryList: observable,
      getEsimProductsList: observable,
      catalog: action,
      getCategories: action,
      getEsimProducts: action,
      search: action,
      autoSuggest: action,
      inventoryStatusService: action,
      products: action,
      pospago: action,
      itemsBySKU: action,
      datasheet: action,
      home: action,
      inventory: action
    });
  }

  catalog(value) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByCategory%2F${value}?lang=${process.env.NEXT_PUBLIC_LOCALE}&currency=${process.env.NEXT_PUBLIC_CURRENCY}`,
    };

    return request.genericHandler(options, null).then((res) => {
      let callback = { action: 'catalog', success: false };
      if (!res.error) {
        clearProductList(res.data);
        callback = { ...callback, data: res.data.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
      }
      this.catalogResponse = callback;
      return callback;
    });
  }

  getCategories() {
    return queryFunction('getCategories', apiCategories);
  }

  getEsimProducts() {

    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/products/esim`,
    };
    return request.genericHandler(options).then((res) => {
      let callback = { action: 'getEsimProducts', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
      }
      this.getEsimProductsList = callback;
      return callback;
    });
  }

  search(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_WCAAS}${process.env.NEXT_PUBLIC_MERCHANT}/productlookup/searchTerm`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'search', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
        this.searchResult = callback;
      } else {
        callback = { ...callback, error: res.error };
        this.searchResult = callback;
      }
      return callback;
    });
  }

  autoSuggest(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_WCAAS}${process.env.NEXT_PUBLIC_MERCHANT}/productlookup/autoSuggest`,
      data: query,
    };

    return request.genericHandler(options).then((res) => {
      let callback = { action: 'autoSuggest', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data, success: true };
        this.autoSuggestResult = callback;
      } else {
        callback = { ...callback, error: res.error };
        this.autoSuggestResult = callback;
      }
      return callback;
    });
  }

  async inventoryStatusService(partNumber) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_CUSTOM_INVENTORY}${process.env.NEXT_PUBLIC_MERCHANT}/status/${partNumber}`,
    };
    return request.genericHandler(options, null, 'inventoryStatus');
  }

  products(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByAttribute`,
      data: query,
    };

    return request.genericHandler(options, null).then((res) => {
      let callback = { action: 'products', success: false };
      if (!res.error) {
        callback = { ...callback, data: res.data.data, success: true };
      } else {
        callback = { ...callback, error: res.error };
      }
      this.catalogResponse = callback;
      return callback;
    });
  }

  async pospago(query) {
    const options = {
      method: 'POST',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/productsByPospago`,
      data: query,
    };

    return genericFunction(options, null, 'pospago');
  }

  async itemsBySKU(query) {
    return queryFunction('itemsBySKU', () => getItemsBySKU(query), false);
  }

  async datasheet(partNumber) {
    const options = {
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_MONGO_API}${process.env.NEXT_PUBLIC_MERCHANT}/products/${partNumber}/datasheet`,
    };

    return genericFunction(options, null, 'datasheet');
  }

  async home(loading) {
    return queryFunction('configHome', () => getConfigHome(), true);
  }

  async inventory(items) {
    const invStatus = items.map(item => this.inventoryStatusService(item.part_number));
    const invStatusRes = await Promise.all(invStatus);
    for (let i = 0; i < invStatusRes.length; i += 1) {
      const inventoryStatus = invStatusRes[i];
      if (inventoryStatus && inventoryStatus.data && inventoryStatus.data.data) {
        if (!inventoryStatus.data.data.available || !inventoryStatus.data.data.buyable
          || inventoryStatus.data.data.quantity < +items[i].quantity) {
          return inventoryStatus.data.data.partnumber;
        }
      }
    }
    return null;
  }
}

export default new CatalogStore();
